import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../../badge-section/BadgeSection";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const AboutUsContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row >
                <Col xs={12} xl={5}  data-aos="fade-up">
                    <BadgeSection
                        marginCustom="0"
                        widthBackground={window.innerWidth < 576 ? "180px" : "230px"}
                        sectionTitle="About Us"
                    />
                </Col>
            </Row>
            <Row className="about-us-description">
                <Col xs={12} xl={5}  data-aos="fade-up">
                    <div>
                        <h1>Closer to the Inventify Center</h1>
                    </div>
                </Col>
                <Col xs={12} xl={7}  data-aos="fade-up">
                    <p>
                        Inventify Center is a platform dedicated to organizing aimed at fostering
                        innovative projects among Young Innovators From Around the World and the wider
                        community.Through Services like incubation, workshops, competitions,
                        and conferences, we aim to facilitate growth and development of participants'
                        potential in innovation.
                    </p>
                </Col>
            </Row>
        </Container>

    );

};

export default AboutUsContent;