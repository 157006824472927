import React from "react";
import {
    Button
} from "react-bootstrap";

const ButtonFill = ({ buttonName, widthCustom, paddingCustom, onClick }) => {

    return (

        <Button
            onClick={onClick}
            style={
                {
                    backgroundColor: '#003456', 
                    border: '1px solid #003456', 
                    borderRadius: '50px', 
                    padding: paddingCustom,
                    cursor: 'pointer',
                    width: widthCustom,
                    fontSize: window.innerWidth < 576 ? '16px' : '18px'
                }
            }
        >
            {buttonName}
        </Button>

    );

};

export default ButtonFill;