import IncubationImage1 from "../images/service/incubation1.jpg";
import IncubationImage2 from "../images/service/incubation2.jpg";
import IncubationImage3 from "../images/service/incubation3.jpg";
import IncubationImage4 from "../images/service/incubation4.jpg";

import MentorshipImage1 from "../images/service/mentorship1.jpg";
import MentorshipImage2 from "../images/service/mentorship2.jpg";
import MentorshipImage3 from "../images/service/mentorship3.jpg";
import MentorshipImage4 from "../images/service/mentorship4.jpg";

import CompetitionImage1 from "../images/service/competition1.png";
import CompetitionImage2 from "../images/service/competition2.jpg";
import CompetitionImage3 from "../images/service/competition3.jpg";
import CompetitionImage4 from "../images/service/competition4.jpg";
import CompetitionImage5 from "../images/service/competition5.jpg";

import ConferenceImage1 from "../images/service/conference1.jpeg";
import ConferenceImage2 from "../images/service/conference2.jpg";
import ConferenceImage3 from "../images/service/conference3.jpg";
import ConferenceImage4 from "../images/service/conference4.jpg";

import SummitImage1 from "../images/service/summit1.jpeg";
import SummitImage2 from "../images/service/summit2.jpg";
import SummitImage3 from "../images/service/summit3.jpg";
import SummitImage4 from "../images/service/summit4.jpg";

import CommunityImage1 from "../images/service/community1.jpeg";
import CommunityImage2 from "../images/service/community2.jpg";
import CommunityImage3 from "../images/service/community3.jpg";
import CommunityImage4 from "../images/service/community4.jpg";

export const ServiceData = {
    "type": "Service",
    "Collections": [
        {
            type: "Incubation",
            id: "1",
            properties:
            {
                title: "Nurturing Ideas, Accelerating Success.",
                picture: IncubationImage1,
                description: "Incubation supports innovators, entrepreneurs, and startups in turning ideas into sustainable businesses, offering mentorship, networks, workspace, and funding to accelerate their journey from concept to market.",
                focus: "This program is focused on delivering holistic support, ensuring that every step toward success is guided and effective.",
                benefit: [
                    {
                        id: "1",
                        title: "Tech Innovators Hub",
                        description: "Tech Innovators Hub is an incubation program specifically designed for technology startups.",
                        picture: IncubationImage3,
                        point: [
                            {
                                id: "1",
                                title: "Investor Connections",
                            },
                            {
                                id: "2",
                                title: "Collaboration Companies",
                            },
                            {
                                id: "3",
                                title: "Networking Opportunities",
                            },
                        ]
                    },
                    {
                        id: "2",
                        title: "Creative Minds Incubation",
                        description: "Creative Minds Incubation is an incubation service focused on the creative industries, such as design, art, and media.",
                        picture: IncubationImage4,
                        point: [
                            {
                                id: "1",
                                title: "Creative Workshops",
                            },
                            {
                                id: "2",
                                title: "Portfolio Development",
                            },
                            {
                                id: "3",
                                title: "Exhibition Opportunities",
                            },
                        ]
                    },
                    {
                        id: "3",
                        title: "Social Impact Accelerator",
                        description: "Social Impact Accelerator is an incubation program designed for startups focused on social impact.",
                        picture: IncubationImage2,
                        point: [
                            {
                                id: "1",
                                title: "Sustainable Business",
                            },
                            {
                                id: "2",
                                title: "Measurement Strategy",
                            },
                            {
                                id: "3",
                                title: "Funding Opportunities",
                            },
                        ]
                    },
                ]
            }
        },
        {
            type: "Mentorship",
            id: "2",
            properties:
            {
                title: "Empowering Growth Through Expert Guidance",
                picture: MentorshipImage1,
                description: "Mentorship connects you with experienced mentors to support your personal and professional growth, offering tailored guidance and resources to help you succeed.",
                focus: "Mentorship links you with knowledgeable experts who help you navigate challenges and accelerate your growth, both personally and professionally.",
                benefit: [
                    {
                        id: "1",
                        title: "Career Advancement",
                        description: "This program is designed for professionals aiming to accelerate their career growth.",
                        picture: MentorshipImage2,
                        point: [
                            {
                                id: "1",
                                title: "Personalized Career",
                            },
                            {
                                id: "2",
                                title: "Leadership Development",
                            },
                            {
                                id: "3",
                                title: "Skill Enhancement",
                            },
                        ]
                    },
                    {
                        id: "2",
                        title: "Entrepreneurship Mentorship",
                        description: "Designed for individuals seeking personal development, this program offers mentorship on topics.",
                        picture: MentorshipImage3,
                        point: [
                            {
                                id: "1",
                                title: "Fundraising Support",
                            },
                            {
                                id: "2",
                                title: "Market Entry Strategies",
                            },
                            {
                                id: "3",
                                title: "Business Model",
                            },
                        ]
                    },
                    {
                        id: "3",
                        title: "Personal Growth Mentorship",
                        description: "Social Impact Accelerator is an incubation program designed for startups focused on social impact.",
                        picture: MentorshipImage4,
                        point: [
                            {
                                id: "1",
                                title: "Time Management",
                            },
                            {
                                id: "2",
                                title: "Confidence Building",
                            },
                            {
                                id: "3",
                                title: "Growth Plans",
                            },
                        ]
                    },
                ]
            }
        },
        {
            type: "Competition",
            id: "3",
            properties:
            {
                title: "Challenge Your Limits, Showcase Your Talent.",
                picture: CompetitionImage1,
                description: "The Competition service is designed to identify and nurture talent across various fields. Through a series of carefully curated contests, participants are given the platform to showcase their skills, gain recognition, and win exciting rewards.",
                focus: "Whether you're competing in technology, business innovation, or other disciplines, our competitions are structured to inspire excellence and foster growth.",
                benefit: [
                    {
                        id: "1",
                        title: "Tech Innovation Challenge",
                        description: "The Tech Innovation Challenge is a competition aimed at tech enthusiasts and innovators.",
                        picture: CompetitionImage2,
                        point: [
                            {
                                id: "1",
                                title: "Skill Development",
                            },
                            {
                                id: "2",
                                title: "Recognition",
                            },
                            {
                                id: "3",
                                title: "Access to Funding",
                            },
                        ]
                    },
                    {
                        id: "2",
                        title: "Startup Pitch Battle",
                        description: "Participants have the chance to pitch their business ideas to a panel of investors and industry experts.",
                        picture: CompetitionImage3,
                        point: [
                            {
                                id: "1",
                                title: "Incubation Support",
                            },
                            {
                                id: "2",
                                title: "Pitch Training",
                            },
                            {
                                id: "3",
                                title: "Investor Connections",
                            },
                        ]
                    },
                    {
                        id: "3",
                        title: "Social Impact Challenge",
                        description: "The Social Impact Challenge is designed for individuals and teams working on projects that create positive social change.",
                        picture: CompetitionImage4,
                        point: [
                            {
                                id: "1",
                                title: "Funding Opportunities",
                            },
                            {
                                id: "2",
                                title: "Visibility",
                            },
                            {
                                id: "3",
                                title: "Social Entrepreneurs",
                            },
                        ]
                    },
                    {
                        id: "4",
                        title: "Business Strategy Competition",
                        description: "Participants are presented with a real-world business case and must develop and present a strategic plan to solve it.",
                        picture: CompetitionImage5,
                        point: [
                            {
                                id: "1",
                                title: "Real-World Experience",
                            },
                            {
                                id: "2",
                                title: "Skill Development",
                            },
                            {
                                id: "3",
                                title: "Career Advancement",
                            },
                        ]
                    },
                ]
            }
        },
        {
            type: "Conference",
            id: "4",
            properties:
            {
                title: "Connecting Minds, Shaping the Future",
                picture: ConferenceImage1,
                description: "The Conference service is designed to bring together professionals, experts, and thought leaders from various industries to share knowledge, network, and explore the latest trends.",
                focus: "Our conferences provide a platform for attendees to engage in meaningful discussions, attend insightful presentations, and develop actionable strategies.",
                benefit: [
                    {
                        id: "1",
                        title: "Tech Innovators Conference",
                        description: "The Tech Innovators Conference is a conference aimed at tech enthusiasts and innovators.",
                        picture: ConferenceImage2,
                        point: [
                            {
                                id: "1",
                                title: "Global Exposure",
                            },
                            {
                                id: "2",
                                title: "Networking Opportunities",
                            },
                            {
                                id: "3",
                                title: "Hands-On Workshops",
                            },
                        ]
                    },
                    {
                        id: "2",
                        title: "Sustainable Business",
                        description: "This conference focuses on sustainability in business, green technologies, and sustainable business models.",
                        picture: ConferenceImage3,
                        point: [
                            {
                                id: "1",
                                title: "Sustainability Insights",
                            },
                            {
                                id: "2",
                                title: "Actionable Strategies",
                            },
                            {
                                id: "3",
                                title: "Collaborative Opportunities",
                            },
                        ]
                    },
                    {
                        id: "3",
                        title: "Global Leadership Conference",
                        description: "A leadership conference aimed at executives, managers, and aspiring leaders across industries.",
                        picture: ConferenceImage4,
                        point: [
                            {
                                id: "1",
                                title: "Leadership Skills",
                            },
                            {
                                id: "2",
                                title: "Strategic Insights",
                            },
                            {
                                id: "3",
                                title: "Career Advancement",
                            },
                        ]
                    },
                ]
            }
        },
        {
            type: "Summit",
            id: "5",
            properties:
            {
                title: "Where Visionaries Converge and Inspire Global Change.",
                picture: SummitImage1,
                description: "The Summit service is a premier platform designed to bring together leaders, innovators, and changemakers from diverse fields.",
                focus: "The summit features networking sessions that foster the exchange of ideas and strategies for driving positive change in the world.",
                benefit: [
                    {
                        id: "1",
                        title: "Sustainability Summit",
                        description: "This summit focuses on sustainable development goals for combating climate change, and promoting green technologies.",
                        picture: SummitImage2,
                        point: [
                            {
                                id: "1",
                                title: "Collaboration",
                            },
                            {
                                id: "2",
                                title: "Networking Opportunities",
                            },
                            {
                                id: "3",
                                title: "Access Latest Research",
                            },
                        ]
                    },
                    {
                        id: "2",
                        title: "Tech Leaders Summit",
                        description: "The Tech Leaders Summit gathers the brightest minds in technology to explore the future of innovation.",
                        picture: SummitImage3,
                        point: [
                            {
                                id: "1",
                                title: "Insights",
                            },
                            {
                                id: "2",
                                title: "Networking Opportunities",
                            },
                            {
                                id: "3",
                                title: "Access to Workshops",
                            },
                        ]
                    },
                    {
                        id: "3",
                        title: "Entrepreneurship Summit",
                        description: "A leadership conference aimed at executives, managers, and aspiring leaders across industries.",
                        picture: SummitImage4,
                        point: [
                            {
                                id: "1",
                                title: "Access to Mentorship",
                            },
                            {
                                id: "2",
                                title: "Strategic Insights",
                            },
                            {
                                id: "3",
                                title: "Career Advancement",
                            },
                        ]
                    },
                ]
            }
        },
        {
            type: "Community",
            id: "6",
            properties:
            {
                title: "Building Connections, Empowering Communities",
                picture: CommunityImage1,
                description: "Whether you're looking to connect with like-minded people, share knowledge, or work together on impactful projects, our community platform provides the space, tools, and support needed to thrive together.",
                focus: "Our Community service is dedicated to fostering a sense of belonging and collaboration among individuals with shared interests and goals.",
                benefit: [
                    {
                        id: "1",
                        title: "Tech Enthusiasts Community",
                        description: "A community for tech enthusiasts to share knowledge, stay updated, and collaborate on innovative projects.",
                        picture: CommunityImage2,
                        point: [
                            {
                                id: "1",
                                title: "Collaboration",
                            },
                            {
                                id: "2",
                                title: "Networking Opportunities",
                            },
                            {
                                id: "3",
                                title: "Access Exclusive Resource",
                            },
                        ]
                    },
                    {
                        id: "2",
                        title: "Entrepreneurship Network",
                        description: "This community is tailored for aspiring and seasoned entrepreneurs who want to share insights on business ventures.",
                        picture: CommunityImage3,
                        point: [
                            {
                                id: "1",
                                title: "Insights",
                            },
                            {
                                id: "2",
                                title: "Networking Opportunities",
                            },
                            {
                                id: "3",
                                title: "Access to Workshops",
                            },
                        ]
                    },
                    {
                        id: "3",
                        title: "Social Impact Circle",
                        description: "A community focused on social impact, where members can connect with others who are passionate about making a difference.",
                        picture: CommunityImage4,
                        point: [
                            {
                                id: "1",
                                title: "Collaboration",
                            },
                            {
                                id: "2",
                                title: "Access to Rosource",
                            },
                            {
                                id: "3",
                                title: "Networking Opportunities",
                            },
                        ]
                    },
                ]
            }
        },
    ]
};