import React from "react";
import {
    Col,
    Image
} from "react-bootstrap";

import Competition1 from "../../assets/images/galleries/competition1.png";
import Competition2 from "../../assets/images/galleries/competition2.jpg";
import Competition3 from "../../assets/images/galleries/competition3.jpg";

import "../../assets/css/style.css";

const CompetitionGallery = () => {

    return (

        <>
            <Col xs={12} xl={4} className="mt-4">
                <div style={{ height: window.innerWidth < 576 ? '300px' : '400px' }}>
                    <Image src={Competition1} style={{ height: '100%', width: '100%', borderRadius: '10px' }} loading="lazy"/>
                </div>
            </Col>
            <Col xs={12} xl={4} className="mt-4">
                <div style={{ height: window.innerWidth < 576 ? '300px' : '400px' }}>
                    <Image src={Competition2} style={{ height: '100%', width: '100%', borderRadius: '10px' }} loading="lazy"/>
                </div>
            </Col>
            <Col xs={12} xl={4} className="mt-4">
                <div style={{ height: window.innerWidth < 576 ? '300px' : '400px' }}>
                    <Image src={Competition3} style={{ height: '100%', width: '100%', borderRadius: '10px' }} loading="lazy"/>
                </div>
            </Col>
        </>

    );

};

export default CompetitionGallery;