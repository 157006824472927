import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../../badge-section/BadgeSection";

import Value1Image from "../../../assets/images/general-images/value-1.png";
import Value2Image from "../../../assets/images/general-images/value-2.png";
import Value3Image from "../../../assets/images/general-images/value-3.png";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const ValueContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);


    return (

        <Container>
            <Row data-aos="fade-up">
                <Col xs={12} xl={5}>
                    <BadgeSection
                        marginCustom="0"
                        widthBackground={window.innerWidth < 576 ? "155px" : "200px"}
                        sectionTitle="Value"
                    />
                </Col>
            </Row>
            <Row className="value-title" data-aos="fade-up">
                <Col xs={12} xl={5}>
                    <div>
                        <h1>At Inventify Center, we believe that successful innovation requires brilliant ideas and strong values.</h1>
                    </div>
                </Col>
                <Col xs={12} xl={{span:4, offset:3}} className="d-flex justify-content-end flex-column">
                    <p>
                        These values guide every innovation and decision we make.
                    </p>
                </Col>
            </Row>
            <Row className="value-image">
                <Col xs={12} xl={4} className="mt-4" data-aos="fade-up">
                    <Image src={Value1Image} alt="Value1Img" loading="lazy"/>
                </Col>
                <Col xs={12} xl={4} className="mt-4" data-aos="fade-up">
                    <Image src={Value2Image} alt="Value2Img" loading="lazy"/>
                </Col>
                <Col xs={12} xl={4} className="mt-4" data-aos="fade-up">
                    <Image src={Value3Image} alt="Value3Img" loading="lazy"/>
                </Col>
            </Row>
        </Container>

    );

};

export default ValueContent;