import React, { useState, useEffect } from "react";
import {
    Container,
    Nav,
    Navbar,
    Image,
    NavDropdown
} from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import ButtonFill from "../../components/button/ButtonFill";

import InventifyCenterLogo from "../../assets/images/general-images/logo-hitam.png";

import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

const NavbarGeneral = () => {

    const [scrolled, setScrolled] = useState(false);
    const [activeLink, setActiveLink] = useState('/');

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 20;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled)
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [scrolled]);

    useEffect(() => {
        setActiveLink(window.location.pathname);
    }, []);


    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#about-us-content';
        } else {
            document.getElementById('about-us-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleMediaClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#media-content';
        } else {
            document.getElementById('media-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleContactClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#contact-content';
        } else {
            document.getElementById('contact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <Navbar className={scrolled ? "navbar-scrolled" : "navbar"} expand="lg" fixed="top">
            <Container style={{ padding: window.innerWidth < 576 ? '0' : null }}>
                <Navbar.Brand className="nav-brand" href="#">
                    <Image src={InventifyCenterLogo} alt="InventifyCenterLogoImage" style={{ width: window.innerWidth < 576 ? '140px' : '160px' }} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="nav-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link onClick={() => window.location.href = '/'} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/' ? 'active' : ''}`}>Home</Nav.Link>
                        <Nav.Link onClick={handleAboutUsClick} className={` ${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#about-us-content' ? 'active' : ''}`} > About Us </Nav.Link>
                        <NavDropdown title="Service" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => window.location.href = `/service/detail/1`}>Incubation</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => window.location.href = `/service/detail/2`}>Mentorship</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => window.location.href = `/service/detail/3`}>Competition</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => window.location.href = `/service/detail/4`}>Conference</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => window.location.href = `/service/detail/5`}>Summit</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => window.location.href = `/service/detail/6`}>Community</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={() => window.location.href = '/events'} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/events' ? 'active' : ''}`}>Events</Nav.Link>
                        <Nav.Link onClick={() => window.location.href = '/galleries'} className={`${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/galleries' ? 'active' : ''}`}>Gallery</Nav.Link>
                        <Nav.Link onClick={handleMediaClick} className={` ${scrolled ? "nav-link-scrolled" : "nav-link"} ${activeLink === '/#media-content' ? 'active' : ''}`}>Media Coverage</Nav.Link>
                        <div className="d-flex nav-btn" onClick={handleContactClick}>
                            <ButtonFill
                                buttonName="Get in Touch"
                            />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );

};

export default NavbarGeneral;