import React from "react";
import {
    Col,
    Image
} from "react-bootstrap";

import Summit1 from "../../assets/images/galleries/summit1.jpg";
import Summit2 from "../../assets/images/galleries/summit2.jpg";
import Summit3 from "../../assets/images/galleries/summit3.jpg";

import "../../assets/css/style.css";

const SummitGallery = () => {

    return (

        <>
            <Col xs={12} xl={4} className="mt-4">
                <div style={{ height: window.innerWidth < 576 ? '300px' : '400px' }}>
                    <Image src={Summit1} style={{ height: '100%', width: '100%', borderRadius: '10px' }} loading="lazy"/>
                </div>
            </Col>
            <Col xs={12} xl={4} className="mt-4">
                <div style={{ height: window.innerWidth < 576 ? '300px' : '400px' }}>
                    <Image src={Summit2} style={{ height: '100%', width: '100%', borderRadius: '10px' }} loading="lazy"/>
                </div>
            </Col>
            <Col xs={12} xl={4} className="mt-4">
                <div style={{ height: window.innerWidth < 576 ? '300px' : '400px' }}>
                    <Image src={Summit3} style={{ height: '100%', width: '100%', borderRadius: '10px' }} loading="lazy"/>
                </div>
            </Col>
        </>

    );

};

export default SummitGallery;