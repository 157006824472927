import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../../badge-section/BadgeSection";

import Media1Image from "../../../assets/images/general-images/media-1.png";
import Media2Image from "../../../assets/images/general-images/media-2.jpg";
import Media3Image from "../../../assets/images/general-images/media-3.png";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const MediaContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5}  data-aos="fade-up">
                    <BadgeSection
                        marginCustom="0"
                        widthBackground={window.innerWidth < 576 ? "160px" : "200px"}
                        sectionTitle="Media"
                    />
                </Col>
            </Row>
            <Row className="media-title">
                <Col xs={12} xl={5}  data-aos="fade-up">
                    <div>
                        <h1>Our media who have covered our results and achievements so far.</h1>
                    </div>
                </Col>
            </Row>
            <Row className="media-answer">
                <Col xs={12} xl={5}  data-aos="fade-up">
                    <div className="media-wrapper-one">
                        <Image src={Media1Image} alt="media1img" style={{width: '100%', borderRadius: '20px'}} loading="lazy"/>
                    </div>
                    <div className="media-description-one">
                        <h5>Citypreneurs Policy Dialogue - Empowering Urban Renaissance: Ulsan's Path to Economic Revitalization</h5>
                        <p>On 12 June 2024, the Citypreneurs Policy Dialogue fostered engaging discussions on the theme "Empowering Urban Renaissance: Ulsan's Path to Economic Revitalization."</p>
                        <a href="https://www.unescap.org/news/citypreneurs-policy-dialogue-empowering-urban-renaissance-ulsans-path-economic-revitalization" target="_blank" rel="noopener noreferrer">Read More</a>
                    </div>
                </Col>
                <Col xs={12} xl={7}>
                    <Row>
                        <Col xs={12} xl={5}  data-aos="fade-up">
                            <div className="media-wrapper-two">
                                <Image src={Media2Image} alt="media1img" style={{width: '100%', height: '100%',borderRadius: '20px'}} loading="lazy"/>
                            </div>
                        </Col>
                        <Col xs={12} xl={7}  data-aos="fade-up">
                            <div className="media-description-two">
                                <h5>GSMA Mobile Innovation Hub Indonesia: Energy and Green Economic Development</h5>
                                <p>As the world’s fourth most populous country and a rising economy in Asia, Indonesia faces challenges in fulfilling its energy needs.</p>
                                <a href="https://www.gsma.com/solutions-and-impact/connectivity-for-good/mobile-for-development/mobile-innovation-hub/indonesia/indonesia-energy-and-green-economic-development/" target="_blank" rel="noopener noreferrer">Read More</a>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12} xl={5}  data-aos="fade-up">
                            <div className="media-wrapper-three">
                                <Image src={Media3Image} alt="media1img" style={{width: '100%', height: '100%',borderRadius: '20px'}} loading="lazy"/>
                            </div>
                        </Col>
                        <Col xs={12} xl={7}  data-aos="fade-up">
                            <div className="media-description-three">
                                <h5>Boosting Startup Growth: What to Look at When Choosing an Acceleration Program</h5>
                                <p>Accelerator and incubator are often one of the first stops for tech startups. They offer product development, sales, and marketing help to entrepreneurs.</p>
                                <a href="https://therecursive.com/what-to-look-at-when-choosing-an-acceleration-program/" target="_blank" rel="noopener noreferrer">Read More</a>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    );

};

export default MediaContent;