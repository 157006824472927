import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Card
} from "react-bootstrap";
import AOS from 'aos';

import HomeLayout from "../../layouts/home/HomeLayout";
import { EventData } from "../../assets/js/events";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";

const Event = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <HomeLayout>
            <Container id="events-content">
                <Row className="events-title">
                    <Col xs={12} xl={{ span: 6, offset: 3 }} data-aos="fade-up">
                        <h1>Exciting Events at Inventify Center</h1>
                    </Col>
                    <Col xs={12} xl={{ span: 6, offset: 3 }} data-aos="fade-up">
                        <p>
                            Our range of Workshops, Seminars, Competitions, and additional events.
                        </p>
                    </Col>
                </Row>
                <Row className="events-main">
                    {EventData.Collections.map((event) => {
                        return (
                            <Col xs={12} xl={4} key={event.id} className="events-sample mt-4" data-aos="fade-up">
                                <Card style={{ width: '100%', border: 'none', borderRadius: '0'}} className="p-2">
                                    <Card.Img variant="top" src={event.properties.image} className="rounded-0 card-image" />
                                    <Card.Body className="py-2 px-0">
                                        <Card.Title className="mt-2" style={{ fontSize: window.innerWidth < 576 ? "16px" : "18px", fontWeight: '600', height: '58px' }}>{event.properties.title}</Card.Title>
                                        <Row className="event-date mt-2">
                                            <Col xs={6} xl={6} className="d-flex justify-content-start align-items-center">
                                                <p style={{ fontSize: window.innerWidth < 576 ? "16px" : "18px"}} >Deadline</p>
                                            </Col>
                                            <Col xs={6} xl={6} className="d-flex justify-content-end align-items-center">
                                                <p className="text-muted" style={{ fontSize: window.innerWidth < 576 ? "14px" : "16px"}}>{event.properties.date}</p>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col xl={12}>
                                                <Card.Link href={event.properties.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: window.innerWidth < 576 ? "16px" : "18px"}}>Read the details</Card.Link>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </HomeLayout>

    );

};

export default Event;