import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../../badge-section/BadgeSection";

import { ServiceData } from "../../../assets/js/service";

import ArrowIcon from "../../../assets/images/icons/arrow.svg";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const ServiceContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5}  data-aos="fade-up">
                    <BadgeSection
                        marginCustom="0"
                        widthBackground={window.innerWidth < 576 ? "170px" : "210px"}
                        sectionTitle="Service"
                    />
                </Col>
            </Row>
            <Row className="service-title-1">
                <Col xs={12} xl={{ span: 4, offset: 4 }} className="text-center"  data-aos="fade-up">
                    <Row>
                        <Col xs={12} xl={{ span: 8, offset: 2 }} >
                            <p>We offer services that are useful for you.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="service-title-2">
                <Col xs={12} xl={{ span: 10, offset: 1 }} className="text-center"  data-aos="fade-up"> 
                    <h1>
                        We help Young Innovators from Across the Globe find their purpose, sharpen their skills, and achieve remarkable success.
                    </h1>
                </Col>
            </Row>
            <Row className="service-answer">
                {ServiceData.Collections.map((service) => {
                    return (
                        <Col xs={12} xl={4} className="mt-4" key={service.id} onClick={() => window.location.href = `/service/detail/${service.id}`} data-aos="fade-up">
                            <div className="service-type-wrapper">
                                <Row>
                                    <Col xs={10} xl={10}>
                                        <h1>{service.properties.title}</h1>
                                    </Col>
                                    <Col xs={2} xl={2} className="d-flex justify-content-end align-items-center">
                                        <Image src={ArrowIcon} className="service-icon"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} xl={12}>
                                        <p>{service.properties.description}</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </Container>

    );

};

export default ServiceContent;