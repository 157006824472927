import React from "react";
import {
    Button
} from "react-bootstrap";

const ButtonNoFill = ({ buttonName, borderCustom, onClick }) => {

    return (

        <Button 
            onClick={onClick}
            style={
                {
                    backgroundColor: 'transparent', 
                    border: borderCustom, 
                    borderRadius: '20px', 
                    padding: '1% 4%', 
                    color: '#000000',
                    cursor: 'pointer',
                    fontSize: window.innerWidth < 576 ? '16px' : '18px'
                }
            }
        >
            {buttonName}
        </Button>

    );

};

export default ButtonNoFill;