import React from "react";

const BadgeSection = ({ sectionTitle, widthBackground, marginCustom }) => {

    return (

        <div style={{ width: widthBackground, backgroundColor: "#F5F5F5", padding: '1%', borderRadius: '20px', marginTop: marginCustom}}>
            <div>
                <span style={{
                    backgroundColor: '#003456',
                    padding: '1% 2%',
                    borderRadius: '20px',
                    color: '#FFFFFF',
                    fontSize: window.innerWidth < 576 ? '14px' : '18px'
                }}>Inventify Center</span>
                <span style={{ marginLeft: '2%', fontSize: window.innerWidth < 576 ? '14px' : '18px'}}>{sectionTitle}</span>
            </div>
        </div>

    );

};

export default BadgeSection;