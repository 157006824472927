import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";

import InventifyCenterLogo from "../../assets/images/general-images/logo-putih.png";
import WALogo from "../../assets/images/icons/WhatsApp.png";
import EmailLogo from "../../assets/images/icons/Gmail.png";
import InstagramLogo from "../../assets/images/icons/Instagram.png";
import LinkedInLogo from "../../assets/images/icons/LinkedIn.png";

import "../../assets/css/style.css";

const FooterComponent = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#about-us-content';
        } else {
            document.getElementById('about-us-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleMediaClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#media-content';
        } else {
            document.getElementById('media-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleServiceClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#service-content';
        } else {
            document.getElementById('service-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

        <div style={{ backgroundColor: '#003456', marginTop: '12%', paddingTop: '35px', paddingBottom: '30px', color: 'white' }}>
            <Container>
                <Row className="footer-quick-menu">
                    <Col xs={12} xl={4}>
                        <Image src={InventifyCenterLogo} alt="InventifyCenterLogoImage" style={{width: window.innerWidth < 576 ? '140px' : '160px'}}/>
                        <Row>
                            <Col className="d-flex mt-4" style={{gap: '20px'}}>
                                <div 
                                    onClick={() => window.location.href = `https://web.whatsapp.com/send?phone=+6285214960974`}
                                    style={{padding: '5px', border: '1px solid #FFFFFF', borderRadius: '8px', cursor: 'pointer'}}
                                >
                                    <Image src={WALogo} style={{ width: "25px" }}/>
                                </div>
                                <div 
                                    onClick={() => window.location.href = `https://www.instagram.com/inventify_center?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==`}
                                    style={{padding: '5px', border: '1px solid #FFFFFF', borderRadius: '8px', cursor: 'pointer'}}
                                >
                                    <Image src={InstagramLogo} style={{ width: "25px" }}/>
                                </div>
                                <div 
                                    onClick={() => window.location.href = `https://www.linkedin.com/in/inventify-center-121893304/`}
                                    style={{padding: '5px', border: '1px solid #FFFFFF', borderRadius: '8px', cursor: 'pointer'}}
                                >
                                    <Image src={LinkedInLogo} style={{ width: "25px" }}/>
                                </div>
                                <div style={{padding: '5px', border: '1px solid #FFFFFF', borderRadius: '8px', cursor: 'pointer'}}>
                                    <Image src={EmailLogo} style={{ width: "25px" }}/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} xl={2}>
                        <h6><strong>Company</strong></h6>
                        <p onClick={() => window.location.href = `/`}>Home</p>
                        <p onClick={handleAboutUsClick}>About Us</p>
                        <p onClick={handleServiceClick}>Service</p>
                        <p onClick={() => window.location.href = `/events`}>Events</p>
                        <p onClick={() => window.location.href = `/galleries`}>Gallery</p>
                        <p onClick={handleMediaClick}>Media Coverage</p>
                    </Col>
                    <Col xs={6} xl={2}>
                        <h6><strong>Service</strong></h6>
                        <p onClick={() => window.location.href = `/service/detail/1`}>Incubation</p>
                        <p onClick={() => window.location.href = `/service/detail/2`}>Mentorship</p>
                        <p onClick={() => window.location.href = `/service/detail/3`}>Competition</p>
                        <p onClick={() => window.location.href = `/service/detail/4`}>Conference</p>
                        <p onClick={() => window.location.href = `/service/detail/5`}>Summit</p>
                        <p onClick={() => window.location.href = `/service/detail/6`}>Community</p>
                    </Col>
                    <Col xs={6} xl={2}>
                        <h6><strong>Impact</strong></h6>
                        <p>Events</p>
                        <p>Speakers & Mentors</p>
                        <p>Startups</p>
                        <p>Youthpreneurs</p>
                        <p>Affiliates & Institution</p>
                    </Col>
                    <Col xs={6} xl={2}>
                        <h6><strong>Resource</strong></h6>
                        <p>Support Center</p>
                        <p>Terms & Condition</p>
                    </Col>
                </Row>
                <div style={{marginTop: '60px', fontSize: '18px'}}>
                    <Row>
                        <Col xs={12} xl={12}>
                            <h6 style={{fontSize: window.innerWidth < 576 ? '16px' : '18px', textAlign: window.innerWidth < 576 ? 'center' : null}}><strong>Your Journey Starts Here at Inventify Center.</strong></h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} xl={5} style={{fontSize: window.innerWidth < 576 ? '16px' : '18px', textAlign: window.innerWidth < 576 ? 'center' : null}}>
                            <p>Connect with like-minded individuals and stay informed. Join Us</p>
                        </Col>
                        <Col xs={12} xl={{span:4,offset:3}} className={window.innerWidth < 576 ? "d-flex justify-content-center" : "d-flex justify-content-end"} style={{fontSize: window.innerWidth < 576 ? '16px' : '18px', textAlign: window.innerWidth < 576 ? 'center' : null}}>
                            <p>© 2024 Inventify Center. All rights reserved.</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

    );

};

export default FooterComponent;