import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import HomeLayout from "../../layouts/home/HomeLayout";
import { ServiceData } from "../../assets/js/serviceDetail";
import BadgeSection from "../../components/badge-section/BadgeSection";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";

const ServiceDetail = () => {

    const params = useLocation();

    const id = (params.pathname).split('/')[3];

    const [expandedBenefitId, setExpandedBenefitId] = useState("1");

    const handleClick = (benefitId) => {
        setExpandedBenefitId(expandedBenefitId === benefitId ? null : benefitId);
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <HomeLayout>
            <Container id="service-detail-content">
                {ServiceData.Collections
                    .filter((service) => service.id === id)
                    .map((service) => {
                        return (
                            <div key={service.id}>
                                <Row>
                                    <Col xl={3} data-aos="fade-up">
                                        <BadgeSection
                                            widthBackground={window.innerWidth < 576 ? "200px" : "245px"}
                                            sectionTitle={service.type}
                                        />
                                    </Col>
                                </Row>
                                <Row className="title mt-4">
                                    <Col xl={7} data-aos="fade-up">
                                        <h1>{service.properties.title}</h1>
                                    </Col>
                                </Row>
                                <Row className="wrapper-head">
                                    <Col xl={12} data-aos="fade-up">
                                        <div style={{ background: '#F5F5F5', height: window.innerWidth < 576 ? '300px' : '500px', width: '100%' }}>
                                            <Image src={service.properties.picture} style={{height: '100%', width: '100%'}}/>
                                        </div>
                                    </Col>
                                    <Col xl={7} data-aos="fade-up">
                                        <p>
                                            {service.properties.description}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="detail-main">
                                    <Col xl={8} data-aos="fade-up">
                                        <h1>
                                            {service.properties.focus}
                                        </h1>
                                    </Col>
                                </Row>
                                {service.properties.benefit.map((benefit) => {
                                    return (
                                        <div key={benefit.id}>

                                            <Row className="accordion">
                                                <Col xl={2} data-aos="fade-up">
                                                    <h1>{benefit.title}</h1>
                                                </Col>
                                                {expandedBenefitId === benefit.id && (
                                                    <>
                                                        <Col className="overflow-auto gap-1" xl={{ span: 3, offset: 1 }} style={{display: window.innerWidth < 576 ? 'flex' : null}} data-aos="fade-up">
                                                            {benefit.point.map((point) => {
                                                                return (
                                                                    <div key={point.id} className="mb-3">
                                                                        <Button style={{ borderRadius: '20px', border: '1px solid #003456', color: '#000000', background: 'transparent', width: window.innerWidth < 576 ? '230px' : null}}>{point.title}</Button>
                                                                    </div>
                                                                )
                                                            })}
                                                        </Col>
                                                        <Col xl={4} data-aos="fade-up">
                                                            <p>
                                                                {benefit.description}
                                                            </p>
                                                            <div style={{ background: '#F5F5F5', height: '250px', width: '100%', marginTop: '25px', marginBottom: window.innerWidth < 576 ? '30px' : null }}>
                                                                <Image src={benefit.picture} style={{height: '100%', width: '100%', borderRadius: '8px'}} loading="lazy"/>
                                                            </div>
                                                        </Col>
                                                    </>
                                                )}
                                                <Col xl={expandedBenefitId === benefit.id ? 2 : { span: 2, offset: 8 }} data-aos="fade-up">
                                                    <Button
                                                        onClick={() => handleClick(benefit.id)}
                                                        style={{
                                                            height: '40px',
                                                            width: '140px',
                                                            borderRadius: '25px',
                                                            backgroundColor: expandedBenefitId === benefit.id ? '#003456' : 'transparent',
                                                            border: '1px solid #003456',
                                                            color: expandedBenefitId === benefit.id ? '#FFFFFF' : '#000000',
                                                            float: window.innerWidth < 576 ? 'left' : 'right'
                                                        }}
                                                    >
                                                        {expandedBenefitId === benefit.id ? 'Hide Details' : 'Detail'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
            </Container>
        </HomeLayout>

    );

};

export default ServiceDetail;