import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import {
    Row,
    Col,
    Container,
    Image,
    Form
} from "react-bootstrap";
import axios from "axios";
import AOS from 'aos';

import ContactImage from "../../../assets/images/general-images/contact-image.png";

import BadgeSection from "../../badge-section/BadgeSection";
import ButtonFill from "../../button/ButtonFill";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const ContactContent = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmitEmail = async (e) => {

        e.preventDefault();

        try {

            const response = await axios.post(
                'https://formspree.io/f/xdknyvaa',
                {
                    name: `${firstName} ${lastName}`,
                    phone,
                    email,
                    message
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            );

            if (response) {
                enqueueSnackbar('Email sent successfully', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 });
            }

        } catch (err) {
            enqueueSnackbar('An error occurred while sending the email', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 });
        }
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <>
            <Image src={ContactImage} alt="ContactImg" data-aos="fade-right"/>
            <Container>
                <Row>
                    <Col xs={12} xl={5} data-aos="fade-up">
                        <BadgeSection
                            marginCustom="0"
                            widthBackground={window.innerWidth < 576 ? "200px" : "260px"}
                            sectionTitle="Get in Touch"
                        />
                    </Col>
                </Row>
                <Row className="contact-form">
                    <Col xs={12} xl={{ span: 6, offset: 6 }} data-aos="fade-up">
                        <div>
                            <h1>Let’s Get in touch</h1>
                            <p className="text-muted">Send us a message to learn more.</p>
                        </div>
                        <div className="form-message">
                            <Form>
                                <Row className="mt-2">
                                    <Col xs={12} xl={6} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label style={{fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="Your first name..." style={{height: window.innerWidth < 576 ? '40px' : '50px'}} onChange={(e) => setFirstName(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} xl={6} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label style={{fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="Your last name..." style={{height: window.innerWidth < 576 ? '40px' : '50px'}} onChange={(e) => setLastName(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={12} xl={12} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label style={{fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="name@example.com" style={{height: window.innerWidth < 576 ? '40px' : '50px'}} onChange={(e) => setEmail(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={12} xl={12} data-aos="fade-up">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label style={{fontSize: window.innerWidth < 576 ? '16px' : '18px' }}>Phone Number</Form.Label>
                                            <Form.Control type="text" placeholder="Your phone number..." style={{height: window.innerWidth < 576 ? '40px' : '50px'}} onChange={(e) => setPhone(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-2" data-aos="fade-up">
                                    <Col xs={12} xl={12}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label style={{fontSize: window.innerWidth < 576 ? '16px' : null }}>Message</Form.Label>
                                            <Form.Control as="textarea" rows={window.innerWidth < 576 ? '3' : '5'} onChange={(e) => setMessage(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col xs={12} xl={12} data-aos="fade-up">
                                        <ButtonFill
                                            onClick={handleSubmitEmail}
                                            widthCustom="100%"
                                            paddingCustom={window.innerWidth < 576 ? "2%" : "2% 4%"}
                                            buttonName="Send Message"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>

    );

};

export default ContactContent;