import React from "react";
import HomeLayout from "../../layouts/home/HomeLayout";
import MainContent from "../../components/home/main-content/MainContent";
import AboutUsContent from "../../components/home/about-us/AboutUs";
import VisionMissionContent from "../../components/home/vision-mission/VisionMission";
import ValueContent from "../../components/home/value/Value";
import ServiceContent from "../../components/home/service/Service";
import PortfolioContent from "../../components/home/portfolio/Portfolio";
import PartnerClientContent from "../../components/home/partner-client/PartnerClient";
import MediaContent from "../../components/home/media/Media";
import ContactContent from "../../components/home/contact/Contact";

import "../../assets/css/style.css";

const Home = () => {

    return (

        <HomeLayout>
            <div id="main-content">
                <MainContent/>
            </div>

            <div id="about-us-content">
                <AboutUsContent/>
            </div>

            <div id="vision-mission-content">
                <VisionMissionContent/>
            </div>
            
            <div id="value-content">
                <ValueContent/>
            </div>
            
            <div id="service-content">
                <ServiceContent/>
            </div>

            <div id="portfolio-content">
                <PortfolioContent/>
            </div>

            <div id="partner-client-content">
                <PartnerClientContent/>
            </div>

            <div id="media-content">
                <MediaContent/>
            </div>

            <div id="contact-content">
                <ContactContent/>
            </div>
        </HomeLayout>

    );

};

export default Home;