import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import ButtonFill from "../../button/ButtonFill";
import StartupsImage from "../../../assets/images/general-images/startups.jpeg";
import EventsImage from "../../../assets/images/general-images/events.jpeg";

import BadgeSection from "../../badge-section/BadgeSection";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const PortfolioContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5} data-aos="fade-up">
                    <BadgeSection
                        marginCustom="0"
                        widthBackground={window.innerWidth < 576 ? "170px" : "220px"}
                        sectionTitle="Portfolio"
                    />
                </Col>
            </Row>
            <Row className="portfolio-title">
                <Col xs={12} xl={5} data-aos="fade-up">
                    <div>
                        <h1>Explore our projects at Inventify Center and discover our innovative initiatives and success stories.</h1>
                    </div>
                </Col>
                <Col xs={12} xl={{ span: 4, offset: 3 }} data-aos="fade-up">
                    <p>
                        From workshops to incubation programs, our portfolio highlights how we
                        support young innovators and foster creativity and growth.
                    </p>
                    <ButtonFill
                        widthCustom="40%"
                        buttonName="Explore More"
                        onClick={() => window.location.href = `/galleries`}
                    />
                </Col>
            </Row>
            <Row className="portfolio-answer">
                <Col xs={12} xl={4} data-aos="fade-up">
                    <hr />
                    <div className="portfolio-sticky">
                        <h1>Events</h1>
                        <p>
                            Inventify Center organizes various events, such as seminars and workshops,
                            to support and develop participants' skills and networks.
                        </p>
                    </div>
                </Col>
                <Col xs={12} xl={{ span: 7, offset: 1 }} data-aos="fade-up">
                    <Row className="portfolio-description">
                        <Col xs={12} xl={4}  data-aos="fade-up">
                            <hr />
                            <div className="portfolio-name">
                                <h1>
                                    Event-Driven Service Optimization
                                </h1>
                            </div>
                        </Col>
                        <Col xs={12} xl={8}  data-aos="fade-up">
                            <hr />
                            <div className="portfolio-description">
                                <p>
                                    Optimizes the performance and reliability of systems using event-driven architectures by ensuring efficient event management.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="portfolio-image">
                        <Col xs={12} xl={12}  data-aos="fade-up">
                            <div className="portfolio-wrapper">
                                <Image src={EventsImage} style={{width: '100%', height: '100%',borderRadius: '20px'}} loading="lazy"/>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="portfolio-answer">
                <Col xs={12} xl={4} data-aos="fade-up">
                    <hr />
                    <div className="portfolio-sticky">
                        <h1>Startups</h1>
                        <p>
                            Inventify Center organizes various events, such as seminars and workshops,
                            to support and develop participants' skills and networks.
                        </p>
                    </div>
                </Col>
                <Col xs={12} xl={{ span: 7, offset: 1 }}>
                    <Row className="portfolio-description">
                        <Col xs={12} xl={4}  data-aos="fade-up">
                            <hr />
                            <div className="portfolio-name">
                                <h1>
                                    Accelerating Startup Growth
                                </h1>
                            </div>
                        </Col>
                        <Col xs={12} xl={8}  data-aos="fade-up">
                            <hr />
                            <div className="portfolio-description">
                                <p>
                                    Offers tailored solutions to enhance efficiency, scalability, and market presence for startups, driving their growth and success.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="portfolio-image">
                        <Col xs={12} xl={12} data-aos="fade-up">
                            <div className="portfolio-wrapper">
                                <Image src={StartupsImage} style={{width: '100%', height: '100%',borderRadius: '20px'}} loading="lazy"/>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="portfolio-view-all">
                <Col xs={12} xl={{ span: 3, offset: 5 }}  data-aos="fade-up">
                    <ButtonFill
                        widthCustom="100%"
                        paddingCustom={window.innerWidth < 576 ? "2%" : "4%"}
                        buttonName="View All Portfolio"
                        onClick={() => window.location.href = `/galleries`}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} xl={{ span: 7, offset: 5 }}>
                    <hr />
                </Col>
            </Row>
        </Container>

    );

};

export default PortfolioContent;