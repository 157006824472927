import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import BadgeSection from "../../badge-section/BadgeSection";
import ButtonFill from "../../button/ButtonFill";
import ButtonNoFill from "../../button/ButtonNoFill";

import ImageMain1 from "../../../assets/images/general-images/image-main-1.png";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const MainContent = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#about-us-content';
        } else {
            document.getElementById('about-us-content').scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const handleServiceClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#service-content';
        } else {
            document.getElementById('service-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={6}>
                    <BadgeSection
                        marginCustom="100px"
                        widthBackground={window.innerWidth < 576 ? "290px" : "360px"}
                        sectionTitle="Your journey begins here"
                    />
                    <div className="header-title">
                        <h1>Empowering Global Young Innovators</h1>
                        <p>We empower young innovators worldwide to excel in academics, careers,
                            leadership, and personal development. Join Inventify Center to find
                            your purpose, hone your skills, and achieve remarkable success.
                        </p>
                    </div>
                    <div className="btn-header-title">
                        <ButtonFill
                            onClick={handleAboutUsClick}
                            buttonName="Get started"
                            paddingCustom="1% 4%"
                        />
                        <ButtonNoFill
                            onClick={handleServiceClick}
                            borderCustom="1px solid #F5F5F5"
                            buttonName="Learn More"
                        />
                    </div>
                    <Row className="header-title-impact">
                        <Col xs={4} xl={4}>
                            <div>
                                <h1>15<span>+</span></h1>
                                <p>New <strong>events</strong> created</p>
                            </div>
                        </Col>
                        <Col xs={4} xl={4}>
                            <div>
                                <h1>35<span>+</span></h1>
                                <p><strong>Speakers & Mentors</strong></p>
                            </div>
                        </Col>
                        <Col xs={4} xl={4}>
                            <div>
                                <h1>175<span>+</span></h1>
                                <p>New <strong>startups</strong> created</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} xl={6} >
                    <Image src={ImageMain1} alt="Image1" className="w-100" loading="lazy" data-aos="fade-up"/>
                    <Row className="header-title-impact-two">
                        <Col xs={12} xl={6} data-aos="fade-up">
                            <div className="col-one">
                                <div>
                                    <h1>50<span>+</span></h1>
                                </div>
                                <div className="col-one-description">
                                    <p><strong>Affiliates & Institutions</strong></p>
                                    <p>Partner and service integration for promotion and expansion.</p>
                                    <ButtonNoFill
                                        borderCustom="1px solid #000000"
                                        buttonName="Learn More"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} xl={6} data-aos="fade-up">
                            <div className="col-two">
                                <h1><strong>Organized Directed Opportunities</strong></h1>
                                <div className="col-two-description">
                                    <p>Fostering innovative projects among Young Innovators From Around the World and the wider community.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    );

};

export default MainContent;