import Event1 from "../images/events/event1.png";
import Event2 from "../images/events/event2.png";
import Event3 from "../images/events/event3.png";
import Event4 from "../images/events/event4.png";
import Event5 from "../images/events/event5.png";
import Event6 from "../images/events/event6.png";
import Event7 from "../images/events/event7.png";
import Event8 from "../images/events/event8.png";
import Event9 from "../images/events/event9.png";
import Event10 from "../images/events/event10.png";
import Event11 from "../images/events/event11.png";
import Event12 from "../images/events/event12.png";
import Event13 from "../images/events/event13.png";
import Event14 from "../images/events/event14.png";
import Event15 from "../images/events/event15.png";

export const EventData = {
    "type": "Event",
    "Collections": [
        {
            type: "Event",
            id: "1",
            properties:
            {
                title: "World Youth Researchers Conference 2024",
                date: "10 September 2024",
                image: Event14,
                link: "https://www.instagram.com/p/C-dGqcHStDQ/"
            }
        },
        {
            type: "Event",
            id: "2",
            properties:
            {
                title: "Calling All Blue Innovators for The ASEAN Blue Innovation Challenge!⁣",
                date: "31 May 2024",
                image: Event5,
                link: "https://www.instagram.com/p/C6RJpflrq9m/"
            }
        },
        {
            type: "Event",
            id: "3",
            properties:
            {
                title: "Innovate for Impact: Designing Sustainable Business Models For Tomorrow's Market",
                date: "26 May 2024",
                image: Event11,
                link: "https://www.instagram.com/p/C7VVk0ov_zt/"
            }
        },
        {
            type: "Event",
            id: "4",
            properties:
            {
                title: "Innovate for Impact: Designing Sustainable Business Models For Tomorrow's Market",
                date: "26 May 2024",
                image: Event12,
                link: "https://www.instagram.com/p/C7ZGe72SVZw/"
            }
        },
        {
            type: "Event",
            id: "5",
            properties:
            {
                title: "Innovate for Impact: Designing Sustainable Business Models For Tomorrow's Market",
                date: "18 May 2024",
                image: Event9,
                link: "https://www.instagram.com/p/C66YgX7LxxF/"
            }
        },
        {
            type: "Event",
            id: "6",
            properties:
            {
                title: "Innovate for Impact: Designing Sustainable Business Models For Tomorrow's Market",
                date: "18 May 2024",
                image: Event10,
                link: "https://www.instagram.com/p/C7GxfJCSzE9/"
            }
        },
        {
            type: "Event",
            id: "7",
            properties:
            {
                title: "Innovate for Impact: Designing Sustainable Business Models For Tomorrow's Market",
                date: "11 May 2024",
                image: Event7,
                link: "https://www.instagram.com/p/C6x71uVLkg_/"
            }
        },
        {
            type: "Event",
            id: "8",
            properties:
            {
                title: "Innovate for Impact: Designing Sustainable Business Models For Tomorrow's Market",
                date: "11 May 2024",
                image: Event8,
                link: "https://www.instagram.com/p/C6x79w9Lk6l/"
            }
        },
        {
            type: "Event",
            id: "9",
            properties:
            {
                title: "INTERNATIONAL YOUTHPRENEUR COMPETITION 2024",
                date: "19 April 2024",
                image: Event13,
                link: "https://www.instagram.com/p/C5YrTgEvEyR/"
            }
        },
        {
            type: "Event",
            id: "10",
            properties:
            {
                title: "INTERNATIONAL YOUTHPRENEUR COMPETITION 2024",
                date: "19 April 2024",
                image: Event6,
                link: "https://www.instagram.com/p/C5YrlT9v1Ee/"
            }
        },
        {
            type: "Event",
            id: "11",
            properties:
            {
                title: "Building Tomorrow Scholarship 2024 (Fully-funded to Montréal, Canada)",
                date: "7 April 2024",
                image: Event4,
                link: "https://www.instagram.com/p/C4NX3w-LZGZ/"
            }
        },
        {
            type: "Event",
            id: "12",
            properties:
            {
                title: "[Fully Funded] ASEAN SOCIAL ENTERPRISE DEVELOPMENT PROGRAM 2024",
                date: "31 March 2024",
                image: Event2,
                link: "https://www.instagram.com/p/C4H1VoMLT45/"
            }
        },
        {
            type: "Event",
            id: "13",
            properties:
            {
                title: "EMPOWERING WOMEN THROUGH ENTREPRENEURSHIP",
                date: "29 February 2024",
                image: Event3,
                link: "https://www.instagram.com/p/C37TqL5rK-7/"
            }
        },
        {
            type: "Event",
            id: "14",
            properties:
            {
                title: "[Fully Funded] US-ASEAN WOMEN'S LEADERSHIP ACADEMY ALUMNY NETWORK",
                date: "29 February 2024",
                image: Event15,
                link: "https://www.instagram.com/p/C39Kg0eL8Vn/"
            }
        },
        {
            type: "Event",
            id: "15",
            properties:
            {
                title: "THE 2ND ASEAN-CHINA INNOVATION AND ENTREPRENEURSHIP COMPETITION",
                date: "15 February 2024",
                image: Event1,
                link: "https://www.instagram.com/p/C39Kg0eL8Vn/"
            }
        },
    ]
};