export const ServiceData = {
    "type": "Service",
    "Collections": [
        {
            type: "Service",
            id: "1",
            properties:
            {
                title: "Incubation",
                description: "Incubation refines participants' skills in ideation, problem-solving, and key areas to accelerate their innovation projects."
            }
        },
        {
            type: "Service",
            id: "2",
            properties:
            {
                title: "Mentorship",
                description: "Personalized mentoring from experts and peer-to-peer sessions to guide and encourage collaboration projects."
            }
        },
        {
            type: "Service",
            id: "3",
            properties:
            {
                title: "Competition",
                description: "Competitions by Inventify Center highlight participants' talents and problem-solving skills globally."
            }
        },
        {
            type: "Service",
            id: "4",
            properties:
            {
                title: "Conference",
                description: "Bringing together minds from around the world to exchange ideas and collaborate through their outstanding articles."
            }
        },
        {
            type: "Service",
            id: "5",
            properties:
            {
                title: "Summit",
                description: "Opportunities for participants to connect with influential leaders, change-makers, and organizations, gaining insights and guidance."
            }
        },
        {
            type: "Service",
            id: "6",
            properties:
            {
                title: "Community",
                description: "Collaborating with schools, universities, and educational institutions to empower students and foster academic excellence."
            }
        }
    ]
};