import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import AOS from 'aos';

import Logo1 from "../../../assets/images/general-images/logo1.png";

import BadgeSection from "../../badge-section/BadgeSection";
import ButtonFill from "../../button/ButtonFill";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const VisionMissionContent = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const handleContactClick = () => {
        if (location.pathname !== '/') {
            window.location.href = '/#contact-content';
        } else {
            document.getElementById('contact-content').scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={3} data-aos="fade-up">
                    <BadgeSection
                        marginCustom="0"
                        widthBackground={window.innerWidth < 576 ? "230px" : "290px"}
                        sectionTitle="Vision & Mission"
                    />
                </Col>
            </Row>
            <Row className="vision-mission-title">
                <Col xs={12} xl={5}  data-aos="fade-up">
                    <Image className="img" src={Logo1} style={{width: '380px'}}/>
                </Col>
                <Col xs={12} xl={6} className="d-flex align-items-center"  data-aos="fade-up">
                    <h1>Inventify Center guiding values and objectives.</h1>
                </Col>
            </Row>
            <hr />
            <Row className="vision-mission-section">
                <Col xs={12} xl={4} data-aos="fade-up">
                    <div className="btn-vm-sticky">
                        <ButtonFill
                            onClick={handleContactClick}
                            buttonName="Become a youthpreneurs"
                            widthCustom="100%"
                            paddingCustom="2.5% 0"
                        />
                    </div>
                </Col>
                <Col xs={12} xl={{span:7, offset: 1}} data-aos="fade-up">
                    <div className="vision-mission-description" data-aos="fade-up">
                        <p>
                            Throughout its journey, Inventify Center has established values and objectives reflected 
                            in the company's vision and mission. The crystallization of these values and goals is 
                            expected to serve as a foundation for Inventify Center and all individuals involved within it.
                        </p>
                    </div>
                    <div className="vision-point" data-aos="fade-up">
                        <h6>Our Vision</h6>
                        <p>
                            We offer events that facilitate growth, learning, and innovation, serving as catalysts 
                            to inspire positive change in the field of innovation projects for participants. 
                        </p>
                    </div>
                    <div className="mission-point" data-aos="fade-up">
                        <h6>Our Mission</h6>
                        <p>
                            We provide innovative and high-quality events that foster the growth and development 
                            of participants' innovative projects.
                        </p>
                        <p>
                            We offer access for participants to expand their knowledge, skills, and networks 
                            in a collaborative and supportive environment. 
                        </p>
                        <p>
                            We support and facilitate the development of innovative projects that promote 
                            creative solutions to global challenges.
                        </p>
                    </div>
                </Col>
            </Row>
            <hr />
        </Container>

    );

};

export default VisionMissionContent;