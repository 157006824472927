import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import AOS from 'aos';

import HomeLayout from "../../layouts/home/HomeLayout";
import IncubationGallery from "../../components/gallery/Incubation";
import MentorshipGallery from "../../components/gallery/Mentorship";
import CommunityGallery from "../../components/gallery/Community";
import SummitGallery from "../../components/gallery/Summit";
import ConferenceGallery from "../../components/gallery/Conference";
import CompetitionGallery from "../../components/gallery/Competition";

import 'aos/dist/aos.css';
import "../../assets/css/style.css";

const Gallery = () => {

    const [selectedGallery, setSelectedGallery] = useState(null);

    useEffect(() => {
        if (selectedGallery === null) {
            setSelectedGallery('Incubation');
        }
    }, [selectedGallery]);

    const showButtonDetail = (buttonName) => {
        setSelectedGallery(buttonName);
    };

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <HomeLayout>
            <Container id="gallery-content">
                <Row className="gallery-title">
                    <Col xs={12} xl={{ span: 8, offset: 2 }} data-aos="fade-up">
                        <h1>Innovative Projects and Milestones at Inventify Center</h1>
                    </Col>
                    <Col xs={12} xl={{ span: 6, offset: 3 }} data-aos="fade-up">
                        <p>
                            Our portfolio highlights innovative achievements and our commitment to advancing creativity and progress.
                        </p>
                    </Col>
                </Row>
                <Row className="gallery-navigation">
                    <Col xs={12} xl={12} className="d-flex overflow-auto" style={{ gap: '25px', alignItems: 'center', whiteSpace: 'nowrap' }} data-aos="fade-up">
                        <span onClick={() => showButtonDetail('Incubation')} className={selectedGallery === 'Incubation' ? 'active' : ''}>Incubation</span>
                        <span onClick={() => showButtonDetail('Mentorship')} className={selectedGallery === 'Mentorship' ? 'active' : ''}>Mentorship</span>
                        <span onClick={() => showButtonDetail('Competition')} className={selectedGallery === 'Competition' ? 'active' : ''}>Competition</span>
                        <span onClick={() => showButtonDetail('Conference')} className={selectedGallery === 'Conference' ? 'active' : ''}>Conference</span>
                        <span onClick={() => showButtonDetail('Summit')} className={selectedGallery === 'Summit' ? 'active' : ''}>Summit</span>
                        <span onClick={() => showButtonDetail('Community')} className={selectedGallery === 'Community' ? 'active' : ''}>Community</span>
                    </Col>
                    <hr />
                </Row>
                {selectedGallery && (
                    <Row className="main-gallery" data-aos="fade-up">
                        {selectedGallery === 'Incubation' && (
                            <IncubationGallery/>
                        )}
                        {selectedGallery === 'Mentorship' && (
                            <MentorshipGallery/>
                        )}
                        {selectedGallery === 'Competition' && (
                            <CompetitionGallery/>
                        )}
                        {selectedGallery === 'Conference' && (
                            <ConferenceGallery/>
                        )}
                        {selectedGallery === 'Summit' && (
                            <SummitGallery/>
                        )}
                        {selectedGallery === 'Community' && (
                            <CommunityGallery/>
                        )}
                    </Row>
                )}
            </Container>
        </HomeLayout>

    );

};

export default Gallery;