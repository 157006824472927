import React, { useEffect } from "react";
import {
    Row,
    Col,
    Container,
    Image
} from "react-bootstrap";
import Marquee from "react-fast-marquee";
import AOS from 'aos';

import Partner1 from "../../../assets/images/partner/apec.png";
import Partner2 from "../../../assets/images/partner/astra-startup.png";
import Partner3 from "../../../assets/images/partner/atsira.png";
import Partner4 from "../../../assets/images/partner/brin.png";
import Partner5 from "../../../assets/images/partner/catalyzon.png";
import Partner6 from "../../../assets/images/partner/createla-impact.png";
import Partner7 from "../../../assets/images/partner/deals.png";
import Partner8 from "../../../assets/images/partner/ej.png";
import Partner9 from "../../../assets/images/partner/exalter.png";
import Partner10 from "../../../assets/images/partner/germancoorp.png";
import Partner11 from "../../../assets/images/partner/insfre.png";
import Partner12 from "../../../assets/images/partner/kabarbuana.png";
import Partner13 from "../../../assets/images/partner/kementrian.png";
import Partner14 from "../../../assets/images/partner/lombabisnis.png";
import Partner15 from "../../../assets/images/partner/monash.png";
import Partner16 from "../../../assets/images/partner/pertamina.png";
import Partner17 from "../../../assets/images/partner/pesantren.png";
import Partner18 from "../../../assets/images/partner/populi.png";
import Partner19 from "../../../assets/images/partner/ristekdikti.png";
import Partner20 from "../../../assets/images/partner/sarasa.png";
import Partner21 from "../../../assets/images/partner/sejutacita.png";
import Partner22 from "../../../assets/images/partner/sre.png";
import Partner23 from "../../../assets/images/partner/tribunews.png";
import Partner24 from "../../../assets/images/partner/unknown1.png";
import Partner25 from "../../../assets/images/partner/unknown2.png";

import BadgeSection from "../../badge-section/BadgeSection";

import 'aos/dist/aos.css';
import "../../../assets/css/style.css";

const PartnerClientContent = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    return (

        <Container>
            <Row>
                <Col xs={12} xl={5} data-aos="fade-up">
                    <BadgeSection
                        marginCustom="0"
                        widthBackground={window.innerWidth < 576 ? "190px" : "240px"}
                        sectionTitle="Trusted by"
                    />
                </Col>
            </Row>
            <Row className="partner-client-marquee">
                <Col xs={12} xl={12} data-aos="fade-up">
                    <Marquee
                        speed={50}
                        gradient={true}
                    >
                        <div className="logo">
                            <Image src={Partner1} loading="lazy"/>
                            <Image src={Partner2} loading="lazy"/>
                            <Image src={Partner3} loading="lazy"/>
                            <Image src={Partner4} loading="lazy"/>
                            <Image src={Partner5} loading="lazy"/>
                            <Image src={Partner6} loading="lazy"/>
                            <Image src={Partner7} loading="lazy"/>
                            <Image src={Partner8} loading="lazy"/>
                            <Image src={Partner9} loading="lazy"/>
                            <Image src={Partner10} loading="lazy"/>
                            <Image src={Partner11} loading="lazy"/>
                            <Image src={Partner12} loading="lazy"/>
                            <Image src={Partner13} loading="lazy"/>
                            <Image src={Partner14} loading="lazy"/>
                            <Image src={Partner15} loading="lazy"/>
                            <Image src={Partner16} loading="lazy"/>
                            <Image src={Partner17} loading="lazy"/>
                            <Image src={Partner18} loading="lazy"/>
                            <Image src={Partner19} loading="lazy"/>
                            <Image src={Partner20} loading="lazy"/>
                            <Image src={Partner21} loading="lazy"/>
                            <Image src={Partner22} loading="lazy"/>
                            <Image src={Partner23} loading="lazy"/>
                            <Image src={Partner24} loading="lazy"/>
                            <Image src={Partner25} loading="lazy"/>
                        </div>
                    </Marquee>
                </Col>
            </Row>
        </Container>

    );

};

export default PartnerClientContent;